import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { desktopDown } from '@/app/common/style/mixins';
import { COLOR } from '@/app/common/style/variables';

export const Wrapper = styled.div`
  margin-bottom: 0;
  padding-top: 16px;
`;

export const Title = styled.h3``;

export const ItemsWrapper = styled.div`
  background-color: ${COLOR.whiteSmoke1};
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 50px 0 30px 0;

  ${desktopDown(css`
    justify-content: space-around;
  `)}
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;
