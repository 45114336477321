import React, { FC } from 'react';
import kebabCase from 'lodash/kebabCase';
import clsx from 'clsx';

import {
  ItemWrapper,
  ItemsContainer,
  ItemsWrapper,
  Title,
  Wrapper,
} from './styles';
import { ContentfulSection } from '../types.d';
import Card, { ContentfulCard } from '@/components/card/Card';
import { ContentfulSys } from '@/types';

export interface GalleryItem extends ContentfulCard {
  sys: ContentfulSys;
}

export interface GallerySection extends ContentfulSection {
  items?: GalleryItem[];
  layout?: 'Carousel' | 'Tiled';
  title?: string;
}

/**
 * Gallery section
 */
const Gallery: FC<GallerySection> = ({
  items = [],
  layout = `Tiled`,
  title,
}) => (
  <Wrapper className={clsx(kebabCase(`Section Cards Layout ${layout}`))}>
    <div className="container">{!!title && <Title>{title}</Title>}</div>
    {!!items.length && (
      <ItemsWrapper>
        <div className="container">
          <ItemsContainer>
            {items.map((item) => (
              <ItemWrapper key={item.id}>
                <Card {...(item as ContentfulCard)} />
              </ItemWrapper>
            ))}
          </ItemsContainer>
        </div>
      </ItemsWrapper>
    )}
  </Wrapper>
);

export default Gallery;
